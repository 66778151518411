import axios from '../../utils/axios'

const prefix = '/exams/bundles-evaluation'

const digitalUrl = 'exams/bundles-evaluation-digital'

const appendUrl = (path: string[] | string, isDigital: boolean) => {
  if (typeof path === 'string') {
    return `${isDigital ? digitalUrl : prefix}/${path}`
  } else {
    return [isDigital ? digitalUrl : prefix, ...path].join('/')
  }
}

type PostQuestionDto = {
  bundleId: string | number
  studExternalId: string | number
  evaluatorType?: string
  questions: {
    partId: number
    questionId: number
    subQuestionId?: number
    marks?: number
  }[]
}

type PostQuestionAdminDto = {
  bundleId: string | number
  studExternalId: string | number
  evaluatorMarks: EvaluatorMarks[]
}

type EvaluatorMarks = {
  evaluatorType?: string
  questions: {
    partId: number
    questionId: number
    subQuestionId?: number
    marks?: number
  }[]
}

export const bundlesEvaluation = {
  async getPermissions() {
    const res = await axios.get(appendUrl('my-permissions', false))
    return res.data
  },
  async fetchDigitalBundlesAssigned() {
    const res = await axios.get(appendUrl('', true))
    return res.data
  },

  async fetchCompletedBundles(search: string) {
    const res = await axios.get(appendUrl('admin/completed', true), {
      params: {
        search,
      },
    })
    return res.data
  },

  async fetchBundleDetails(id: string) {
    const res = await axios.get(appendUrl([id], true))

    return res.data
  },

  async updateBundleBooklets(bundleId: number) {
    return axios.post(appendUrl(`${bundleId}/booklets/update`, true))
  },

  async fetchBundleDetailsSuperAdmin(id: string) {
    const res = await axios.get(
      appendUrl(`${[id]}/completed-booklets/admin`, true),
    )

    return res.data
  },

  async fetchBookletDetails({
    bundleId,
    bookletId,
    isSuperAdmin,
  }: {
    bundleId: number | string
    bookletId: number | string
    isSuperAdmin: string
  }) {
    const res = await axios.get(appendUrl('sheets', true), {
      params: {
        studExternalId: bookletId,
        bundleId,
        isSuperAdmin,
      },
    })
    return res.data
  },

  async fetchBookletDetailsSuperAdmin({
    bundleId,
    bookletId,
  }: {
    bundleId: number | string
    bookletId: number | string
  }) {
    const res = await axios.get(appendUrl('booklet-super-admin', true), {
      params: {
        studExternalId: bookletId,
        bundleId,
      },
    })
    return res.data
  },

  async postSheetMarks(body: FormData) {
    const res = await axios.post(appendUrl('booklets/sheets/marks', true), body)
    return res.data
  },

  async completeEvaluation(postBody: {
    bundleId: number
    studExternalId: number
    status: string
    hasReviewedAllPages: boolean
    evaluatorType?: string
    remarks?: string
  }) {
    return axios.post(appendUrl('booklets/evaluation/complete', true), postBody)
  },

  async revokeEvaluation(postBody: {
    bundleId: number
    studExternalId: number
  }) {
    return axios.post(appendUrl('booklets/evaluation/revoke', true), postBody)
  },
  async completeBundleEvaluation(postBody: {
    bundleId: number
    evaluationId?: number
  }) {
    return await axios.post(
      appendUrl('bundles/evaluation/complete', true),
      postBody,
    )
  },
  async reassignEvaluator(postBody: {
    bundleId: number
    evaluatorId: number
    evaluatorType: string
  }) {
    return await axios.post(
      appendUrl('booklets/evaluation/re-assign-evaluator', true),
      postBody,
    )
  },

  async postQuestionMarks(postBody: PostQuestionDto) {
    return axios.post(appendUrl('booklets/marks', true), postBody)
  },

  async postQuestionMarksAdmin(postBody: PostQuestionAdminDto) {
    return axios.post(appendUrl('booklets/marks/final-update', true), postBody)
  },
}
