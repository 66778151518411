import { ErrorBoundary } from '@campxdev/shared'
import { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { mainRoutes } from 'router/main'

function App() {
  useEffect(() => {
    const handleRightClick = (e: MouseEvent) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleRightClick)
    return () => {
      document.removeEventListener('contextmenu', handleRightClick)
    }
  }, [])

  const app = useRoutes(mainRoutes)
  return <ErrorBoundary>{app}</ErrorBoundary>
}

export default App
