import { axiosErrorToast } from '@campxdev/shared'
import Cookies from 'js-cookie'
import axios from 'utils/axios'

export default function logout() {
  axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_HOST,
    url: '/auth-server/auth/evaluator-logout',
  })
    .then((res) => {
      if (
        process.env.NODE_ENV === 'development' ||
        window.location.origin.split('campx')[1] == '.dev'
      ) {
        Cookies.remove('campx_session_key')
        Cookies.remove('campx_evaluator_key')
        Cookies.remove('campx_tenant')
        window.location.href = window.location.origin + '/campx'
      } else {
        window.location.href = '/'
      }
    })
    .catch((err) => {
      axiosErrorToast('Unable To Logout.')
    })
}
