import { axiosErrorToast, FormTextField } from '@campxdev/shared'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import axios from 'utils/axios'

function ChangePassword({ close }) {
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  })
  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, control, reset } = useForm({})

  const onSubmit = async (formData) => {
    setIsLoading(true)
    const { oldPassword, newPassword, confirmPassword } = formData
    if (newPassword === confirmPassword) {
      try {
        await axios.put('/exams/bundles-evaluation/change-password', {
          oldPassword,
          newPassword,
        })
        toast.success('Password Changed Successfully')
        setIsLoading(false)
        reset()
        close()
      } catch (error) {
        axiosErrorToast(error)
        setIsLoading(false)
      }
    } else {
      toast.error('New Password, Confirm Password must be same')
      setIsLoading(false)
    }
  }

  const fields = [
    { label: 'Old Password', name: 'oldPassword' },
    { label: 'New Password', name: 'newPassword' },
    { label: 'Confirm Password', name: 'confirmPassword' },
  ]

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2} direction="column">
        {fields.map((item) => {
          return (
            <>
              <FormTextField
                label={item.label}
                control={control}
                name={item.name}
                type={showPassword[item.name] ? 'text' : 'password'}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            [item.name]: !prev[item.name],
                          }))
                        }
                        edge="end"
                      >
                        {showPassword[item.name] ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )
        })}

        <Stack direction={'row'} gap={2} sx={{ marginTop: '20px' }}>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            type="submit"
            endIcon={
              isLoading && (
                <CircularProgress
                  style={{ color: 'white' }}
                  size="30px"
                  thickness={1.7}
                />
              )
            }
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

export default ChangePassword
