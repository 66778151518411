import { evaluatorAuth } from './auth-server/auth'
import { bundlesEvaluation } from './exams/bundles-evaluation'
import { openApis } from './exams/open-apis'

export const EXAMS = {
  bundlesEvaluation,
  openApis,
}

export const AUTH = {
  evaluatorAuth,
}
