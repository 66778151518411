import { withRouteWrapper, withSuspense } from '@campxdev/shared'
import PageNotFound from '@campxdev/shared/src/components/PageNotFound'
import AppLayout from 'layouts/AppLayout'
import DashboardLayout from 'layouts/DashboardLayout'
import EditorLayout from 'layouts/EditorLayout'
import OpenLinkLayout from 'layouts/OpenLinkLayout'
import PrintLayout from 'layouts/PrintLayout/PrintLayout'
import { ForgotPassword } from 'pages/ForgotPassword'
import { ResetPassword } from 'pages/ResetPassword'
import { lazy } from 'react'
import { baseRoutes } from './baseRoutes'
import { editorRoutes, scriptViewRoutes } from './editor'
import { individualRoutes } from './individualRoutes'
const LoginPage = lazy(() => import('pages/LoginPage'))

export const mainRoutes = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        element: <DashboardLayout />,
        children: withRouteWrapper(baseRoutes),
      },
      {
        path: 'editor',
        element: <EditorLayout />,
        children: withRouteWrapper(editorRoutes),
      },
    ],
  },

  {
    path: '/open',
    element: <OpenLinkLayout />,
    children: withRouteWrapper(scriptViewRoutes),
  },
  {
    path: '/login',
    element: withSuspense(<LoginPage />),
  },
  {
    path: '/forgot-password',
    element: withSuspense(<ForgotPassword />),
  },
  {
    path: '/set-password',
    element: withSuspense(<ResetPassword isReset={false} />),
  },
  {
    path: '/reset-password',
    element: withSuspense(<ResetPassword isReset={true} />),
  },
  {
    path: '/print',
    element: <PrintLayout />,
    children: withRouteWrapper(individualRoutes),
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
