import { campxLogoPrimary, isDevelopment } from '@campxdev/shared'
import { Box, Typography } from '@mui/material'
import FreshDeskHelpButton from './FreshdeskButton'
import UserBox from './UserBox'
import {
  StyledHeader,
  StyledImageWrapper,
  StyledLink,
  StyledLogosWrapper,
} from './styles'

export default function AppHeader({ clientLogo, user }) {
  return (
    <StyledHeader>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <AppLogo clientLogo={clientLogo} />
      </Box>
      <Box className="actions">
        <FreshDeskHelpButton />
        <UserBox username={user.fullName} profileIcon={user.picture} />
      </Box>
    </StyledHeader>
  )
}

const AppLogo = ({ clientLogo }) => {
  return (
    <StyledLink to={'/'}>
      <StyledLogosWrapper>
        <StyledImageWrapper>
          <img src={campxLogoPrimary} />
        </StyledImageWrapper>
        <Box
          sx={{
            height: '26px',
            width: '2px',
            background: 'gray',
          }}
        ></Box>
        <StyledImageWrapper>
          {isDevelopment ? (
            <Typography variant="h1">Developer</Typography>
          ) : (
            <img
              src={clientLogo}
              onError={(e: any) => {
                e.target.src = campxLogoPrimary
              }}
            />
          )}
        </StyledImageWrapper>
      </StyledLogosWrapper>
    </StyledLink>
  )
}
