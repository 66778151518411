import {
  ConfirmContextProvider,
  DialogProvider,
  ErrorModalProvider,
  GlobalNetworkLoadingIndicator,
  MuiThemeProvider,
  QueryClientProvider,
  ToastContainer,
  isDevelopment,
} from '@campxdev/shared'
import RootModal from '@campxdev/shared/src/contexts/RootModal'
import Cookies from 'js-cookie'
import { ReactNode, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

export const campxTenantKey = Cookies.get('campx_tenant')
export const urlTenantKey = window.location.pathname.split('/')[1]

export default function Providers({ children }: { children: ReactNode }) {
  useEffect(() => {
    if (!urlTenantKey) {
      if (isDevelopment) {
        window.location.replace(window.location.origin + `/${urlTenantKey}`)
      }
      if (campxTenantKey)
        window.location.replace(window.location.origin + `/${campxTenantKey}`)
    }
  }, [])

  return (
    <BrowserRouter basename={urlTenantKey}>
      <QueryClientProvider>
        <MuiThemeProvider>
          <ConfirmContextProvider>
            <DialogProvider>
              <ErrorModalProvider>{children}</ErrorModalProvider>
              <GlobalNetworkLoadingIndicator />
              <RootModal />
              <ToastContainer />
            </DialogProvider>
          </ConfirmContextProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
