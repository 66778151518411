import { lazy } from 'react'

const BundlesAssigned = lazy(
  () => import('./ExternalExam/DigitalEvaluation/AssignedBundles'),
)
const BundleView = lazy(
  () => import('./ExternalExam/DigitalEvaluation/BundleView/BundleView'),
)

const EvaluationEditor = lazy(
  () => import('./ExternalExam/DigitalEvaluation/ExternalExamEvaluation'),
)
const ExamsView = lazy(() => import('./ExamsView'))
const StudentScriptView = lazy(() => import('./StudentScriptView'))

// Reports
const ScrutinyReport = lazy(
  () => import('./ExternalExam/DigitalEvaluation/Reports/ScrutinyReport'),
)
const EvaluationPercentageDifferenceReport = lazy(
  () =>
    import(
      './ExternalExam/DigitalEvaluation/Reports/EvaluationPercentageDifferenceReport'
    ),
)

// Internal Exam
export * from './InternalExam'

export {
  BundlesAssigned,
  BundleView,
  EvaluationEditor,
  EvaluationPercentageDifferenceReport,
  ExamsView,
  ScrutinyReport,
  StudentScriptView,
}
