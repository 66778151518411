import {
  ForgotPasswordProps,
  LoginProps,
  LoginResult,
  ResendOtpProps,
  ResendOtpResult,
  ResetPasswordProps,
  VerifyOtpProps,
} from 'interfaces/auth.interface'
import axios from '../../utils/axios'

const prefix = '/auth-server/auth'

const appendUrl = (path: string[] | string) => {
  if (typeof path === 'string') {
    return `${prefix}/${path}`
  } else {
    return [prefix, ...path].join('/')
  }
}

export const evaluatorAuth = {
  async evaluatorLogin(body: LoginProps) {
    const res = await axios.post<LoginResult>(
      appendUrl('evaluator-login'),
      body,
    )
    return res.data
  },

  async resendOtp(params: ResendOtpProps) {
    const res = await axios.post<ResendOtpResult>(
      appendUrl('evaluator-resend-otp'),
      params,
    )
    return res.data
  },

  async verifyOtp(params: VerifyOtpProps) {
    const res = await axios.post(appendUrl('evaluator-verify-otp'), params)
    return res.data
  },

  async forgotPassword(params: ForgotPasswordProps) {
    const res = await axios.post(appendUrl('evaluator-forgot-password'), params)
    return res.data
  },

  async resetPassword(params: ResetPasswordProps) {
    const res = await axios.post(`${prefix}/evaluator-reset-password`, params)
    return res.data
  },
}
