import { HelpOutline } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default function FreshDeskHelpButton() {
  const handleOpenFreshDeskWidget = () => {
    try {
      ;(window as any)?.openFreshDeskWidget()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error launching Freshdesk')
    }
  }

  return (
    <IconButton color="secondary" onClick={handleOpenFreshDeskWidget}>
      <HelpOutline />
    </IconButton>
  )
}
