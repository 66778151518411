import { ErrorBoundary } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'
import { EvaluatorStore } from 'shared-state/UserStore'
import EvaluationAppHeader from './EvaluationAppHeader'

export default function EditorLayout() {
  const { evaluator } = EvaluatorStore.useState((s) => s)

  return (
    <>
      <EvaluationAppHeader user={evaluator} />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
