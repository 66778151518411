import { Store } from 'pullstate'

interface IEditorStore {
  extMax: number
  imageUrl: string
  bundleDetails: any
  booklet: any
  subject: any
  questionPaper: string
  answerPaper: string
  paperSchema: string
}

const editorStore = new Store<IEditorStore>({
  extMax: null,
  bundleDetails: null,
  imageUrl: null,
  booklet: null,
  subject: null,
  questionPaper: '',
  answerPaper: '',
  paperSchema: '',
})

// export default editorStore
const initEditorData = ({
  booklet,
  bundleDetails,
  paperUploads,
  subject,
}: {
  booklet: any
  bundleDetails: any
  paperUploads: any[]
  subject: any
}) => {
  editorStore.update((s) => {
    s.subject = subject
    s.extMax = booklet.extMax
    s.imageUrl = booklet.bookletKeyUrl
    s.bundleDetails = bundleDetails
    s.booklet = booklet
    s.questionPaper = paperUploads?.find(
      (item) => item.type === 'question_paper',
    )?.url
    s.answerPaper = paperUploads?.find(
      (item) => item.type === 'answer_sheet',
    )?.url
    s.paperSchema = paperUploads?.find(
      (item) => item.type === 'paper_shema',
    )?.url
  })
  ScriptViewStore.update((s) => {
    s.imageUrl = booklet.bookletKeyUrl
  })
}

export const EditorStore = {
  initEditorData,

  store: editorStore,
  useState: (props?: any): IEditorStore => editorStore.useState(props),
}

interface IScriptViewStore {
  imageUrl: string
}

export const ScriptViewStore = new Store<IScriptViewStore>({ imageUrl: null })
