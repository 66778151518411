import { ErrorBoundary } from '@campxdev/shared'
import { Box } from '@mui/material'
import Tabs from 'components/Tabs/Tabs'
import { Outlet } from 'react-router-dom'
import { EvaluatorStore } from 'shared-state/UserStore'
import AppHeader from './AppHeader'

export default function DashboardLayout() {
  const { evaluator, assets } = EvaluatorStore.useState((s) => s)

  const tabs = [
    { path: '/digital/bundles-assigned', label: 'Digital Evaluations' },
    { path: '/physical/bundles', label: 'Physical Evaluation' },
    { path: '/internal/bundles', label: 'Internal Evaluation' },
    ...(evaluator?.isAdmin ? [{ path: '/exams-view', label: 'Exams' }] : []),
  ]
  return (
    <>
      <AppHeader clientLogo={assets?.logo || ''} user={evaluator || ''} />
      <Box>
        <Tabs tabs={tabs} />
      </Box>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
