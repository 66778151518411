import { Box, styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)(() => ({
  textDecoration: 'none',
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  height: '64px',
  boxShadow: '0px 2px 10px #0000001a',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .actions': {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '14px',
  },
}))

export const StyledLogosWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '10px',
  transition: 'background ease 0.3s',
  borderRadius: '5px',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.05)',
  },
}))

export const StyledImageWrapper = styled('div')`
  width: auto;
  height: 24px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const StyledUser = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: '5px',
  transition: 'background 0.2s ease',
  padding: '5px 16px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:hover': {
    background: theme.palette.secondary.light,
  },
}))

export const StyledEditorHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 15px',
  height: '64px',
  '.MuiTypography-root': {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}))
