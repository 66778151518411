import { alpha, Box, styled } from '@mui/material'

export const StyledSidebarContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 170px)',
  width: '450px',
  border: theme.borders.grayLight,
  borderRadius: '10px',

  '& .header': {
    borderRadius: '10px 10px 0 0',
    background: theme.palette.secondary.light,
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px',
  },
  '& .body': {
    height: '90%',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
}))

export const StyledBundleListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  height: '90px',
  display: 'flex',
  borderBottom: theme.borders.grayLight,
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 0.2s ease',

  '&:hover': {
    background: alpha(theme.palette.secondary.main, 0.02),
  },

  '& .left': {
    paddingLeft: '10px',
    marginRight: 'auto',
    '& .MuiTypography-root': {
      marginTop: '4px',
      '& > span': {
        color: theme.palette.secondary.main,
      },
    },
  },
  '& .right': {
    paddingRight: '15px',
  },
  '&:before': {
    transition: 'all 0.2s ease',
    content: "''",
    height: '80%',
    width: '4px',
    borderRadius: '2px',
    background: 'transparent',
    ...(isActive && {
      background: theme.palette.common.yellow,
    }),
  },

  ...(isActive && {
    background: alpha(theme.palette.secondary.main, 0.035),
  }),
}))

export const StyledBundleDetailsHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '70px',
  borderBottom: theme.borders.grayLight,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 1rem',
}))

export const StyledTextContainer = styled(Box)<{
  disabled: true | false
}>(({ theme, disabled }) => ({
  width: '150px',
  backgroundColor: `${disabled ? theme.palette.secondary.light : 'white'}`,
  border: `1px solid #12121233`,
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  '& fieldset': {
    display: 'none',
  },
  '& p': {
    color: '#222222',
  },
}))

export const TableContainer = styled(Box)({
  maxHeight: '70vh',
  width: '70%',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
})
