import { styled } from '@mui/material'

const StyledPageContent = styled('div')({
  padding: '0 20px 20px 20px',
  marginTop: '24px',
})

export function PageContent(props) {
  return (
    <StyledPageContent style={props.style}>{props.children}</StyledPageContent>
  )
}
