import { KeyboardBackspace } from '@mui/icons-material'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { EditorStore } from 'store/editor'
import FreshDeskHelpButton from './FreshdeskButton'
import UserBox from './UserBox'
import { StyledEditorHeader, StyledHeader } from './styles'

/**
 * Component representing the header of the Evaluation Application.
 * Displays the subject and booklet details along with user and help button.
 */
export default function EvaluationAppHeader({ user }) {
  // Destructuring state values from the EditorStore
  const { booklet, subject } = EditorStore.useState((s) => s)

  return (
    <StyledHeader>
      {/* Header left section with course details */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <EditorHeader subject={subject} booklet={booklet} />
      </Box>

      {/* Header right section with help button and user box */}
      <Box className="actions">
        <FreshDeskHelpButton />
        <UserBox username={user?.fullName} profileIcon={user?.picture} />
      </Box>
    </StyledHeader>
  )
}

/**
 * Component for displaying detailed course and booklet information in the header.
 * Includes navigation back functionality.
 */
function EditorHeader({ subject, booklet }) {
  const navigate = useNavigate() // Hook for navigation

  return (
    <StyledEditorHeader>
      {/* Stack to align course details horizontally */}
      <Stack
        alignItems={'center'}
        divider={
          <Divider
            orientation="vertical"
            sx={{ background: 'white' }}
            flexItem
          />
        }
        sx={{
          // Hide the first divider (which appears after the first child)
          '& > :first-child + .MuiDivider-root': {
            display: 'none',
          },
        }}
        flexDirection="row"
        gap={'10px'}
      >
        {/* Navigation back button */}
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspace />
        </IconButton>

        {/* Course details */}
        <Typography sx={{ fontSize: '14px' }}>{`Course : ${
          subject?.name || ''
        }`}</Typography>
        <Typography sx={{ fontSize: '14px' }}>{`Course Code : ${
          subject?.subjectCode || ''
        }`}</Typography>
        <Typography sx={{ fontSize: '14px' }}>{`Barcode : ${
          booklet?.barcode || '--'
        }`}</Typography>
      </Stack>
    </StyledEditorHeader>
  )
}
